import { portalEndpoint } from '../utils'

export const login = async (email: string, password: string): Promise<any> => {
    const res = await fetch(`${portalEndpoint}/auth/login`, {
        method: 'POST',
        body: JSON.stringify({ email, password }),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
    })
    return await res.json();
}

export const logout = async (email: string): Promise<any> => {
    try {
        const res = await fetch(`${portalEndpoint}/auth/logout`, {
            method: 'GET',
            credentials: 'include'
        })
        return await res.json();
    } catch (error) {
        alert(error)
    }

}