import React, { useEffect, useState } from 'react'
import { Box, Typography, LinearProgress } from '@mui/material'
import { getRecentSubscribers, getRecentRenewals, getSubscriptionsByProtocolType } from '../api/dashboard'
import dayjs from 'dayjs'
import DashboardTable from '../components/DashboardTable'


interface subscriptionData {
  created_at: string
  currency: string
  email: string
  family_name: string
  given_name: string
  price: number
  product_id: string
  subscription_type: string;
  user_id?: number
}

interface renewalData extends subscriptionData {
  name: string
}

interface SortedSubscriptionData {
  subscriptionType: string;
  date: string;
  name: string;
  email: string;
  currency: string;
  user_id?: number
}

interface SubscriptionsByProtocolTypeData {
  count: number
  protocol_type_id: string
}

interface SortedSubscriptionsByProtocolTypeData {
  protocolType: string
  count: number
}


export default function Dashboard() {

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [recentSubscriberList, setRecentSubscriberList] = useState<SortedSubscriptionData[]>([])
  const [recentUpgradesList, sestRecentUpgradesList] = useState<SortedSubscriptionData[]>([])
  const [recentRenewalsList, setRecentRenewalsList] = useState<SortedSubscriptionData[]>([])
  const [subscriptionsByProtocolTypeList, setSubscriptionsByProtocolTypeList] = useState<SubscriptionsByProtocolTypeData[]>([])


  const getProtocolTypeName = (protocolTypeId: string): string => {
    const protocolTypeIdNum = parseInt(protocolTypeId)
    switch (protocolTypeIdNum) {
      case (1):
        return 'No Hip Precautions'
      case (2):
        return 'Total Knee Replacement'
      case (3):
        return 'Lateral Approach'
      case (4):
        return 'NonSurgical'
      case (5):
        return 'Patellar Tendon'
      case (6):
        return 'Hamstring Tendon'
      case (7):
        return 'Anterior Approach'
      case (8):
        return 'Pre-surgical'
      case (9):
        return 'Posterior Approach'
      case (10):
        return 'Quadriceps Tendon Graft'
      case (11):
        return 'Allograft Cadaver Graft'
      case (12):
        return 'Knee Strengthening Exercises - A'
      case (14):
        return 'Knee Strengthening Exercises - A'
      case (16):
        return 'Hip Strengthening Exercises'
      case (17):
        return 'Knee Strengthening Exercises - B'
      default: 
        return 'unknown'
    }
  }

  useEffect(() => {
    const fetchSubscribers = async () => {
      setIsLoading(true)
      const createSubscriptionData = (
        subscriptionType: string,
        date: string,
        name: string,
        email: string,
        currency: string,
        user_id?: number
      ): SortedSubscriptionData => {
        return { 
          subscriptionType: formatSubscription(subscriptionType), 
          date: dayjs(date).format('MMM DD, YYYY'), 
          name: name === "null null" ? '-' : name.toString(), 
          email, 
          currency,
          user_id
        };
      }

      const createSubscriptionsByProtocolTypeData = (
        protocol_type_id: string,
        count: number
      ): SortedSubscriptionsByProtocolTypeData => {
        return { protocolType: getProtocolTypeName(protocol_type_id), count }
      }

      const recentSubscribers = await getRecentSubscribers()
      const recentRenewals = await getRecentRenewals()
      const subscriptionsByProtocolType = await getSubscriptionsByProtocolType()
      // console.log({ subscriptionsByProtocolType })
      setRecentSubscriberList(recentSubscribers.newSubscriptions.filter((subscription: subscriptionData) => !subscription.product_id.includes('upgrade')).map((subscription: subscriptionData) => createSubscriptionData(subscription.product_id, subscription.created_at, `${subscription.given_name} ${subscription.family_name}`, subscription.email, subscription.currency, subscription.user_id)))
      sestRecentUpgradesList(recentSubscribers.newSubscriptions.filter((subscription: subscriptionData) => subscription.product_id.includes('upgrade')).map((subscription: subscriptionData) => createSubscriptionData(subscription.product_id, subscription.created_at, `${subscription.given_name} ${subscription.family_name}`, subscription.email, subscription.currency, subscription.user_id)))
      setRecentRenewalsList(recentRenewals.recentRenewals.map((renewal: renewalData) => createSubscriptionData(renewal.subscription_type, renewal.created_at, `${renewal.given_name} ${renewal.family_name}`, renewal.email, renewal.currency, renewal.user_id)))
      setSubscriptionsByProtocolTypeList(subscriptionsByProtocolType.subsByProtocolType.sort((a: SubscriptionsByProtocolTypeData, b: SubscriptionsByProtocolTypeData) => b.count - a.count).map((subscriptionType: SubscriptionsByProtocolTypeData) => createSubscriptionsByProtocolTypeData(subscriptionType.protocol_type_id, subscriptionType.count)))
      setIsLoading(false)
    }

    fetchSubscribers()
  }, [])

  const formatSubscription = (value: string | number) => {
    switch (value) {
      case ('monthly_subscription'):
        return 'Monthly'
      case ('annual_subscription'):
        return 'Annual'
      case ('premium_annual_subscription'):
        return 'Premium Annual'
      case ('premium_plus_annual_subscription'):
        return 'Premium Plus Annual'
      case ('lifetime_subscription'):
        return 'Total Care'
      default:
        return value.toString()
    }
  }

  return (
    <Box>
    {
    isLoading ?
    <LinearProgress />
    :
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Typography sx={{ marginTop: '30px' }} variant="h4">Subscriptions in the Last 30 Days</Typography>

        <DashboardTable 
          dataList={recentSubscriberList}
          title="Recent Subscribers"
          type="newSubscriptions"
        />
        <DashboardTable
          dataList={recentUpgradesList}
          title="Recent Upgrades"
          type="upgrades"
        />
        <DashboardTable
          dataList={recentRenewalsList}
          title="Recent Renewals"
          type="renewals"
        />
        <DashboardTable
          dataList={subscriptionsByProtocolTypeList}
          title="Subscriptions By Protocol Type"
          type="subscriptionsByProtocolType"
        />
      </Box>
    }
    </Box>
  )
}
