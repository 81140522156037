import React, { useEffect, useState } from 'react'
import { fetchPhysios, createPhysio, deletePhysio } from '../api/admin'
import { usePhysio } from "../context/physioContext"

export default function Admin() {

  // const [physios, setPhysios] = useState<{id: number, given_name: string, family_name: string, email: string, picture: string}[]>([])
  const [givenName, setGivenName] = useState('')
  const [familyName, setFamilyName] = useState('')
  const [email, setEmail] = useState('')

  // useEffect(() => {
  //   const getPhysios = async () => {
  //     const data = await fetchPhysios()
  //     setPhysios(data.physios)
  //   }
  //   getPhysios()
  // }, [])

  const { physios, setPhysios } = usePhysio()

  const handleDelete = async (email: string) => {
    if (physios) {
      setPhysios(physios.filter((physio: any) => physio.email !== email))
      await deletePhysio(email)
    }
  }

  const handleCreate = async () => {
    // TODO - run a null check for givenName, familyName and email
    const data = await createPhysio(givenName, familyName, email)
    console.log(data.newPhysio[0][0])
    if (physios && physios.length > 0) {
      setPhysios([...physios, data.newPhysio[0][0]])
    } else {
      setPhysios([data.newPhysio[0][0]])
    }
  }

  return (
    <div>
      <h1>Physiotherapists</h1>
      {physios && physios.map((physio: any, idx: number) => (
        <div style={{ display: 'flex', flexDirection: 'row'}} key={physio.email}>
        <div>{physio.given_name} {physio.family_name} </div>
        {physio.given_name !== 'Nirtal' && <button onClick={() => handleDelete(physio.email)}> DELETE </button>}
        </div>
      ))}
      <h1>Add a Physiotherapist</h1>
      <input placeholder="Given Name" onChange={(e) => setGivenName(e.target.value)}/>
      <input placeholder="Family Name"onChange={(e) => setFamilyName(e.target.value)} />
      <input placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
      <button onClick={() => handleCreate()}>SUBMIT</button>
    </div>
  )
}
