import { portalEndpoint } from '../utils'

export const fetchPhysios = async () => {
    const res = await fetch(`${portalEndpoint}/api/physios`, {
        method: 'GET',
        // body: JSON.stringify({ customer, pageNumber }),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return await res.json();
}

export const createPhysio = async (givenName: string, familyName: string, email: string, picture = null,) => {
    const res = await fetch(`${portalEndpoint}/api/physio/create`, {
        method: 'POST',
        body: JSON.stringify({ givenName, familyName, picture, email }),
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
    });
    return res.json();
}

export const deletePhysio = async (email: string) => {
    const res = await fetch(`${portalEndpoint}/api/physio/delete`, {
        method: 'DELETE',
        body: JSON.stringify({ email }),
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
    });
    return await res.json();
}