import { useState, useContext, createContext, useCallback } from 'react';
import { logout } from '../api/auth'
import { useNavigate } from 'react-router-dom'

interface User {
  role: 'admin' | 'general',
  name: string
  email: string
  id: number
}

interface IAuthContext {
  loggedInUser: User | undefined | null
  handleLogout: (email: string) => Promise<any> | null
  setLoggedInUser: React.Dispatch<React.SetStateAction<User | null | undefined>>;
}


export const AuthContext = createContext<IAuthContext>({
  loggedInUser: undefined,
  setLoggedInUser: () => null,
  handleLogout: (email: string) => null
}) 

interface Props {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<Props> = ({ children }): JSX.Element => {

  const navigate = useNavigate()

  const [loggedInUser, setLoggedInUser] = useState<User | undefined | null>(null)


  const handleLogout = useCallback(async (email: string): Promise<any> => {
     const res = await logout(email)
     if (res.success) {
      navigate('/')
      setLoggedInUser(null)
     }
  }, [navigate])

  return (
    <AuthContext.Provider value={{ loggedInUser, handleLogout, setLoggedInUser }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth(): IAuthContext {
  return useContext(AuthContext);
}