export const appColors = {
  app: {
    background: '#FFF',
    navColor: '#fff',
    mainColor: '#00afb3',
    bodyColor: '#F7F7FA',
    textColor: '#454F63',
    button: '#64AC57'
  }
}

export const brandColors = {
  brand: {
    primary: '#00afb3',
    secondary: '#17233D',
    authinticate: '#FFFFFF'
  }
}