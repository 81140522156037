import React, { useEffect, useState } from 'react';
import dayjs from "dayjs"
import {
  Box,
  TextField,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Checkbox,
  Paper,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material"
import InfoIcon from '@mui/icons-material/Info';
import { visuallyHidden } from "@mui/utils"
import { useNavigate} from "react-router-dom"
import { usePhysio } from "../context/physioContext"
import ReactJson from 'react-json-view';

interface PhysioHash {
  [key: number]: string
}

export default function SearchTable({...props}) {

  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof Data>('created_at');
  const [rows, setRows] = useState([])
  const [physioHash, setPhysioHash] = useState<PhysioHash>({})
  const [open, setOpen] = useState<any>(false);
  const [selectedItem, setSelectedItem] = useState(null);

  interface Data {
    id: string;
    name: string;
    email: string;
    videoPTSessions: number;
    assignedPhysio: string | null;
    created_at: string;
    customer: any
  }

  type Order = 'asc' | 'desc';

  interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
    // children: React.ReactNode; 
  }

  interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
  }

  const { tableData } = props

  const navigate = useNavigate()
  

  function createData(
    id: string,
    name: string,
    email: string,
    videoPTSessions: number,
    assignedPhysio: string | null,
    created_at: string,
    customer: any
  ): Data {
    return {
      id,
      name,
      email,
      videoPTSessions,
      assignedPhysio,
      created_at,
      customer
    };
  }

  function descendingComparator(a: any, b: any, orderBy: keyof any) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
  ) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // const rows = tableData.map((item: any) => (
  //   createData(item.name, item.email, item.videoPTSessions, item.assignedPhysio)
  // ))

  const { physios } = usePhysio()
  
  const physioHashData: PhysioHash = {}

  physios && physios.forEach((physio: any) => {
    physioHashData[physio.id] = `${physio.given_name} ${physio.family_name}`
  })


  const getPhysioName = (id: number) => {
    if (physioHash) {
      return physioHashData[id]
    } else {
      return 'unknown'
    }
  }

  useEffect(() => {
    setRows(tableData.map((item: any) => (
      createData(item.id, item.name, item.email, item.videoPTSessions, item.assignedPhysio, item.created_at, item.customer)
    )))
  }, [tableData])

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleNavigate = (e: any, id: string, assignedPhysio: string | null) => {
    navigate(`/customer/${id}`);
  }

  const handleClickOpen = (item: any) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedItem(null);
  };

  const EnhancedTableHead = (props: EnhancedTableProps) => {
    const { order, orderBy, onRequestSort } = props;
    
    const createSortHandler =(property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const headCells: readonly HeadCell[] = [
    {
      id: 'id',
      numeric: false,
      disablePadding: true,
      label: 'Id',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
    },
    {
      id: 'email',
      numeric: true,
      disablePadding: false,
      label: 'Email',
    },
    {
      id: 'videoPTSessions',
      numeric: true,
      disablePadding: false,
      label: 'Video PT Sessions',
    },
    {
      id: 'assignedPhysio',
      numeric: true,
      disablePadding: false,
      label: 'Physiotherapist',
    },
    {
      id: 'created_at',
      numeric: false,
      disablePadding: false,
      label: 'Joined At',
    },
    // {
    //   id: 'subscriber',
    //   numeric: true,
    //   disablePadding: false,
    //   label: 'Is Subscriber?',
    // },
  ];

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>Json Data</DialogTitle>
        <DialogContent>
          {selectedItem && (
            <Box>
              <Typography variant="caption" color="textSecondary" fontSize={12}>
                <ReactJson src={selectedItem} displayDataTypes={false} enableClipboard={false} collapsed={1} />
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    
      <TableContainer>
        <Table
          sx={{ minWidth: 750, marginHorizontal: '80px'}}
          aria-labelledby="tableTitle"
          size='medium'
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
            <TableBody>
              {rows.sort(getComparator(order, orderBy))
                .map((row: any, index: any) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                            style={{ cursor: 'pointer' }}
                          >
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              align='left'
                            >
                              <IconButton onClick={() => handleClickOpen({...row.customer })} size="small">
                                <InfoIcon />
                              </IconButton>
                              {row.id}
                            </TableCell>
                            <TableCell 
                              align="right"
                              onClick={(event: any) => handleNavigate(event, row.id, row.assignedPhysio)}
                              >
                                <Typography color='blue' fontSize={14}>
                                  {row.name}
                                </Typography>
                            </TableCell>
                            <TableCell 
                              align="right"
                              onClick={(event: any) => handleNavigate(event, row.id, row.assignedPhysio)}
                              >
                                <Typography color='blue' fontSize={14}>
                                  {row.email}
                                </Typography>
                            </TableCell>
                            <TableCell align="center">{row.videoPTSessions}</TableCell>
                            <TableCell align="right">{getPhysioName(row.assignedPhysio)}</TableCell>
                            <TableCell align="center">{row.created_at ? dayjs(row.created_at).format('MMM DD, YYYY (HH:mm)') : 'N/A'}</TableCell>
                          </TableRow>
                      );
                    })}
            </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
