import { portalEndpoint } from '../utils'
import { Customer } from '../types/Customer'

export const searchCustomer = async (customerId: number): Promise<any> => {
    const res = await fetch(`${portalEndpoint}/api/customer`, {
        method: 'POST',
        body: JSON.stringify({ customerId }),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
    })
    return await res.json();
}

export const searchCustomers = async (customer: string, pageNumber: number): Promise<any> => {
    const res = await fetch(`${portalEndpoint}/api/customers/?customer=${customer}&pageNumber=${pageNumber}`, {
        method: 'GET',
        // body: JSON.stringify({ customer, pageNumber }),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    return await res.json()
}

export const byPassSubscription = async (customerId: number, is_enabled: boolean, start_time: any, end_time: any) => {

    if (!is_enabled) {
        start_time = new Date();
        end_time = new Date();
    }

    if (is_enabled) {

        if (!start_time || !end_time) {

            let emptyDate = !start_time ? 'start' : 'end';
            let dateError = `Please select valid ${emptyDate} date for bypass subscription.`;
            alert(dateError);
            throw new Error(dateError);
        }

        if (start_time > end_time) {
            let dateError = "Bypass subscription start time must be less than end time.";
            alert(dateError);
            throw new Error(dateError);
        }

        start_time = new Date(start_time);
        end_time = new Date(end_time);
    }

    start_time.setHours(0);
    start_time.setMinutes(0);
    start_time.setSeconds(0);
    end_time.setHours(23);
    end_time.setMinutes(59);
    end_time.setSeconds(59);

    const res = await fetch(`${portalEndpoint}/api/customer/bypass-subscription/${customerId}`, {
        method: 'PATCH',
        body: JSON.stringify({ is_enabled, start_time, end_time }),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    return await res.json();
}

export const deleteCustomer = async (password: string, customerId: number, userId: number) => {
    const res = await fetch(`${portalEndpoint}/api/customer/delete`, {
        method: 'DELETE',
        body: JSON.stringify({ password, customerId, userId }),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    return res.json();
}

export const adjustVideoPTSessions = async (amount: number, customerId: number): Promise<any> => {
    const res = await fetch(`${portalEndpoint}/api/customer/videopt-session/update`, {
        method: 'POST',
        body: JSON.stringify({ amount, customerId }),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    return await res.json()
}

export const saveNote = async (textContent: string, physio: number, customer: number): Promise<any> => {
    const res = await fetch(`${portalEndpoint}/api/customer/note`, {
        method: 'POST',
        body: JSON.stringify({ textContent, physio, customer }),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    return await res.json()
}

export const getNotes = async (customer: number): Promise<any> => {
    const res = await fetch(`${portalEndpoint}/api/customer/notes/${customer}`, {
        method: 'GET',
        // body: JSON.stringify({ customer, pageNumber }),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    return await res.json();
}

export const deleteNote = async (id: string) => {
    const res = await fetch(`${portalEndpoint}/api/customer/note/delete`, {
        method: 'DELETE',
        body: JSON.stringify({ noteId: id }),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    return res.json();
}

export const addPhysioToCustomer = async (physio: number, customerId: number): Promise<any> => {
    const res = await fetch(`${portalEndpoint}/api/customer/add-physio`, {
        method: 'POST',
        body: JSON.stringify({ physio, customerId }),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    return await res.json();
}

export const removePhysioToCustomer = async (physio: number, customerId: number): Promise<any> => {
    const res = await fetch(`${portalEndpoint}/api/customer/remove-physio`, {
        method: 'POST',
        body: JSON.stringify({ physio, customerId }),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    return res.json();
}