import React, { useState } from 'react'
import { Box, TextField, Button, Typography } from  "@mui/material"
import logo from '../assets/img/icon.png'
import { font } from '../themes/fonts'
import { appColors } from '../themes/colors'
import { login } from '../api/auth'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/authContext'

export default function Login() {

  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const navigate = useNavigate();

  const { setLoggedInUser } = useAuth()

  type FormType = 'email' | 'password'

  const handleFormChange = (form: FormType, text: string) => {
    if (form === 'email') {
      setEmail(text)
      return 
    }

    if (form === 'password') {
      setPassword(text)
      return 
    }
  }

  const handleLogin = async () => {
    console.log('logging in')    
    const loginResult = await login(email, password)
    console.log(loginResult)
    if (loginResult.success === true) {
      setLoggedInUser(loginResult.user)
      navigate('/search')
    }

  }

  return (
    <Box mt={15} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={logo} alt="logo" style={{height: '180px' }}/>
      <Typography
        variant="h6"
        noWrap
        sx={{
          display: { md: 'flex' },
          fontFamily: font.regular,
          color: appColors.app.button,
          fontWeight: 700,
          textDecoration: 'none',
        }}
      >
        PT Portal
      </Typography>

      <TextField
        required
        label="Email"
        value={email}
        onChange={e => handleFormChange("email", e.target.value)}
        margin="normal"
      />

      <TextField
        required
        label="Password"
        value={password}
        onChange={e => handleFormChange("password", e.target.value)}
        margin="normal"
        type="password"
      />

      <Button
        onClick={() => handleLogin()}
        variant="contained"
        size="large"
      >
        LOG IN 
      </Button>
    </Box>
  )
}
