import './App.css';
import Customers from './pages/Customers'
import Dashboard from './pages/Dashboard'
import New from './pages/New'
import Admin from './pages/Admin'
import Login from './pages/Login'
import Search from './pages/Search'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import "@fontsource/nunito-sans"
import "@fontsource/nunito-sans/700.css"
import { ThemeProvider } from '@mui/material/styles';
import theme from './themes/muiTheme'
import AppBarMenu from './components/Appbar'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProtectedRoute from './components/ProtectedRoute'
import { AuthProvider } from '../src/context/authContext'
import { PhysioProvider } from '../src/context/physioContext'

function App() {
  return (
      <ThemeProvider theme={theme}>
          <BrowserRouter>
            <AuthProvider>
            <PhysioProvider>
              <Routes>
                <Route index element={<Login />} />
                <Route path="/*" element={
                  <ProtectedRoute redirectPath="/">
                    <LoggedIn />
                  </ProtectedRoute>
                }/>
              </Routes>
              </PhysioProvider>
            </AuthProvider>
          </BrowserRouter>
      </ThemeProvider>

  );
}

const LoggedIn = () => {
  return (
    <div className='container'>
      <AppBarMenu />
      <Routes>
        <Route path="/search" element={<Search />} />
        <Route path="/customer/:id" element={<Customers />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/new" element={<New />} />
        <Route path="*" element={<p style={{ marginTop: '100px' }}>There's nothing here: 404!</p>} />

      </Routes>
    </div>
  )
}

export default App;
