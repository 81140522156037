import React, { useEffect, useState } from "react"
import SearchTable from '../components/SearchTable';
import {
  Box,
  TextField,
  Button,
  Typography,
  Pagination,
  LinearProgress
} from "@mui/material"
import { searchCustomers } from "../api/customer"

export default function Search() {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [page, setPage] = useState<number>(1)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [searchCount, setSearchCount] = useState<number>(0)
  const [searched, setSearched] = useState<boolean>(false)
  const [searchResults, setSearchResults] = useState([])

  const handleFormChange = (e: string) => {
    setSearched(false)
    setSearchTerm(e)
    setPage(1)
  }

  const handleSearch = async () => {
    setIsLoading(true);
    const data = await searchCustomers(searchTerm.trim(), 1)
    setSearchResults(data.customers)
    setSearchCount(parseInt(data.count))
    setSearched(true);
    setIsLoading(false);
  }

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') handleSearch()
  }

  const convertSearchData = (customers: any) => {
    const tableData = searchResults.map((customer: any) => {
      return {
        id: customer.id,
        sub: customer.sub,
        name: customer.name !== customer.email ? customer.name : '-', 
        email: customer.email, 
        videoPTSessions: customer.video_pt_sessions, 
        assignedPhysio: customer.assigned_physio, 
        currentTrack: customer.current_track_id,
        created_at: customer.created_at,
        customer
      }
    })
    return tableData
  }

  useEffect(() => {
    const searchAfterPageChange = async () => {
      setIsLoading(true);
      const data = await searchCustomers(searchTerm.trim(), page)
      setSearchResults(data.customers)
      setSearchCount(parseInt(data.count))
      setIsLoading(false);
    }
    searchAfterPageChange()
  }, [page])

  return (
    <>
      { !!isLoading ? (<LinearProgress />) : '' }

      <Box sx={{ marginTop: "30px" }} mx={3}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <TextField
            sx={{ width: "50%", marginRight: "15px" }}
            size="small"
            onChange={e => handleFormChange(e.target.value)}
            id="outlined-basic"
            label="Search by user information..."
            variant="outlined"
            helperText={errorMessage ? errorMessage : ""}
            error={!!errorMessage}
            onKeyDown={(e) => handleKeyPress(e)}
          />
          <Button
            variant="contained"
            disabled={searchTerm.length < 3}
            onClick={() => handleSearch()}
          >
            Search
          </Button>
        </Box>
        {searchResults.length > 0 && (
          <Box>
            <Typography>
              There are <strong>{searchCount}</strong> results { searched === true ? `for ${searchTerm}` : ''  }
            </Typography>
            <SearchTable
              tableData={convertSearchData(searchResults)}
            />
            <Pagination count={Math.ceil(searchCount / 10)} showFirstButton showLastButton page={page} onChange={(e, pageNumber) => setPage(pageNumber)}/>
          </Box>
        )}
      </Box>
    </>
  )
}
