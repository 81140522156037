import React, { useState, useCallback, useMemo } from "react"
import {
  BaseEditor,
  createEditor,
  Descendant,
  Editor,
  Transforms,
  Text,
  Node,
} from "slate"
import { Slate, Editable, withReact, ReactEditor } from "slate-react"
import { HistoryEditor } from "slate-history"
import { ContentCopy } from "@mui/icons-material"
import { saveNote } from "../api/customer"
import { Button } from "@mui/material"

declare module "slate" {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor
    Element: CustomElement
    Text: CustomText
  }
}

export type CustomEditor = BaseEditor & ReactEditor & HistoryEditor

export type ParagraphElement = {
  type: "heading" | "paragraph" | "code" | null
  children: CustomText[]
}

export type HeadingElement = {
  type: "heading" | "paragraph" | "code" | null
  level: number
  children: CustomText[]
}

export type CustomElement = ParagraphElement | HeadingElement

export type FormattedText = { text: string; bold?: true }

export type CustomText = FormattedText

export default function TextEditor({ ...props }) {
  const initialValue: Descendant[] = [
    {
      type: "paragraph",
      children: [{ text: "Type your notes here." }],
    },
  ]

  const [editor] = useState(() => withReact(createEditor()))
  const [textContent, setTextContent] = useState("")
  // const [allContent, setAllContent] = useState(initialValue)
  const { customerId, setNotes, notes } = props

  const serialize = (value: any) => {
    return (
      value
        // Return the string content of each paragraph in the value's children.
        .map((n: any) => Node.string(n))
        // Join them all with line breaks denoting paragraphs.
        .join("\n")
    )
  }

  // Define a deserializing function that takes a string and returns a value.
  const deserialize = (string: string) => {
    // Return a value array of children derived by splitting the string.
    return string.split("\n").map((line: any) => {
      return {
        children: [{ text: line }],
      }
    })
  }

  // TODO - change the physio id from 3 to whichever physio is logged in to the account
  const saveTextContent = async (content: string) => {
    const result = await saveNote(content, 3, parseInt(customerId))
    // console.log(result.newNote[0].body)
    setNotes([...notes, result.newNote[0]])
  }

  const CodeElement = (props: any) => {
    return (
      <pre {...props.attributes}>
        <code>{props.children}</code>
      </pre>
    )
  }

  const DefaultElement = (props: any) => {
    return <p {...props.attributes}>{props.children}</p>
  }

  const renderElement = useCallback((props: any) => {
    switch (props.element.type) {
      case "code":
        return <CodeElement {...props} />
      default:
        return <DefaultElement {...props} />
    }
  }, [])

  const renderLeaf = useCallback((props: any) => {
    return <Leaf {...props} />
  }, [])

  const CustomEditor = {
    isBoldMarkActive(editor: any) {
      const [match] = Editor.nodes(editor, {
        match: (n: any) => n.bold === true,
        universal: true,
      })

      return !!match
    },

    isCodeBlockActive(editor: any) {
      const [match] = Editor.nodes(editor, {
        match: (n: any) => n.type === "code",
      })

      return !!match
    },

    toggleBoldMark(editor: any) {
      const isActive = CustomEditor.isBoldMarkActive(editor)
      Transforms.setNodes(
        editor,
        { bold: isActive ? undefined : true },
        { match: (n: any) => Text.isText(n), split: true }
      )
    },

    toggleCodeBlock(editor: any) {
      const isActive = CustomEditor.isCodeBlockActive(editor)
      Transforms.setNodes(
        editor,
        { type: isActive ? null : "code" },
        { match: n => Editor.isBlock(editor, n) }
      )
    },
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: 'center'
      }}
    >
      <div
        style={{
          border: "1px solid slategray",
          minWidth: "35vw",
          borderRadius: '5px',
          marginTop: '20px',
          minHeight: '200px'
        }}
      >
        <Slate
          editor={editor}
          value={initialValue}
          onChange={value => {
            const isAstChange = editor.operations.some(
              op => "set_selection" !== op.type
            )
            if (isAstChange) {
              // Save the value to Local Storage.
              const content = JSON.stringify(value)
              // console.log(serialize(value))
              setTextContent(serialize(value))
              // setAllContent(value)
            }
          }}
        >
          {/* <div> */}
          {/* <button
        onMouseDown={event => {
          event.preventDefault()
          CustomEditor.toggleBoldMark(editor)
        }}
      >
        Bold
      </button> */}
          {/* <button
        onMouseDown={event => {
          event.preventDefault()
          CustomEditor.toggleCodeBlock(editor)
        }}
      >
        Code Block
      </button> */}

          {/* </div> */}
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            onKeyDown={event => {
              if (!event.ctrlKey) {
                return
              }

              switch (event.key) {
                // When "`" is pressed, keep our existing code block logic.
                case "`": {
                  event.preventDefault()
                  const [match] = Editor.nodes(editor, {
                    match: (n: any) => n.type === "code",
                  })
                  Transforms.setNodes(
                    editor,
                    { type: match ? "paragraph" : "code" },
                    { match: n => Editor.isBlock(editor, n) }
                  )
                  break
                }

                // When "B" is pressed, bold the text in the selection.
                case "b": {
                  event.preventDefault()
                  Transforms.setNodes(
                    editor,
                    { bold: true },
                    // Apply it to text nodes, and split the text node up if the
                    // selection is overlapping only part of it.

                    // type: isText, node: Text
                    { match: (n: any) => Text.isText(n), split: true }
                  )
                  break
                }
              }
            }}
          />
        </Slate>
      </div>
      <Button style={{ marginTop: '20px' }} variant="contained" onClick={() => saveTextContent(textContent)}>
        SAVE
      </Button>
    </div>
  )
}

const Leaf = (props: any) => {
  return (
    <span
      {...props.attributes}
      style={{ fontWeight: props.leaf.bold ? "bold" : "normal" }}
    >
      {props.children}
    </span>
  )
}
