import { useState, useContext, useEffect, createContext } from 'react';
import { fetchPhysios } from '../api/admin'

interface Physio {
  id: number
  given_name: string
  family_name: string
  email: string
  picture: string
}

interface IPhysioContext {
  physios: Physio[] | undefined | null
  setPhysios: React.Dispatch<React.SetStateAction<Physio[] | null | undefined>>;
}

export const PhysioContext = createContext<IPhysioContext>({
  physios: undefined,
  setPhysios: () => null,
}) 

interface Props {
  children: React.ReactNode;
}

export const PhysioProvider: React.FC<Props> = ({ children }): JSX.Element => {

  useEffect(() => {
    const getPhysios = async () => {
      const data = await fetchPhysios()
      setPhysios(data.physios)
    }
    getPhysios()
  }, [])

  const [physios, setPhysios] = useState<Physio[] | undefined | null>(null)

  return (
    <PhysioContext.Provider value={{ physios, setPhysios }}>
      {children}
    </PhysioContext.Provider>
  );
}

export function usePhysio(): IPhysioContext {
  return useContext(PhysioContext);
}