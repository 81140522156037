import React from "react"
import { useAuth } from "../context/authContext"
import { Outlet, RouteProps, Navigate } from "react-router-dom"

interface Props extends RouteProps {
  children: JSX.Element
  // isAllowed: boolean
  redirectPath: string
}

export default function ProtectedRoute({
  // isAllowed,
  redirectPath = "/",
  children
}: Props) {
  const { loggedInUser } = useAuth()

  if (!loggedInUser) {
    return (<>
      <Navigate to={redirectPath} replace />
    </>
    )
  }
  return  children ? children : <Outlet />
}
