import { portalEndpoint } from '../utils'

export const getRecentSubscribers = async () => {
    const res = await fetch(`${portalEndpoint}/api/dashboard/subscription/recents`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return await res.json();
}

export const getRecentRenewals = async () => {
    const res = await fetch(`${portalEndpoint}/api/dashboard/subscription/recent-renewals`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return await res.json();
}

export const getSubscriptionsByProtocolType = async () => {
    const res = await fetch(`${portalEndpoint}/api/dashboard/subscription/list-by-protocol-type`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return await res.json();
}